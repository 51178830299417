/*sm   ≥640px*/
@media (min-width: 640px) {}
/*md    ≥768px*/
@media screen and (min-width: 768px) {}
/*lg   ≥1024px*/
@media screen and (min-width: 1024px) {}
/*xl   ≥1280px*/
@media screen and (min-width: 1280px) {}
/*2xl  ≥1536px*/
@media screen and (min-width: 1536px) {}



.App {
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  position: relative;
  padding-bottom: 200px;
}

#bgVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  filter: brightness(0.3);
}

.logoImgWrapper {
/*  min-height: 72px;*/
}

.mainContainer {
  position: relative;
  top: 0;
  left: 0;
  padding-top: 80px;
}

.languageBtns {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
}

.logoImg {
  width: 500px;
  max-width: 90%;
}

.carImgWrapper {
  width: 400px;
  max-width: 90%;
  position: relative;
  padding-left: 1.25%;
  padding-right: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
  align-self: center;
  overflow-x: clip;
}

.carBackground {
  position: relative;
  top: 0;
  left: 10%;
  transform: rotate(-136deg);
  width: 100%;
}

.carImg {
  position: absolute;
  top: 24%;
  left: 8%;
  width: 88.88%;
}


.carAndDescription {
  max-width: 100%;
  padding-top: 60px;
}

.description {
  padding: 0 30px;
  width: 680px;
  max-width: 100%;
  backdrop-filter: blur(20px);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;;
}

.headerBorder {
  width: 60px;
}

.contactAndReseWrapper {
  padding-top: 60px;
  width: 1104px;
  max-width: 100vw;
}

.whatsAppBtn {
  cursor: pointer;
}

.whatsAppBtn:hover {
  filter: brightness(1.2);
}

.contactsWrapper {
  width: 400px;
  max-width: 100vw;
  padding: 20px;
  backdrop-filter: blur(20px);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
}

.cardWrapper {
  width: 620px;
  max-width: 100vw;
  padding: 0 10px;
}

.formCard {
  overflow: hidden;
}

.firmNrWrapper {
  color: white;
  font-size: 0.8em;
  position: absolute;
  left: 20px;
  bottom: 20px;
}

.firmNr {
  padding-left: 10px;
}

.imgWrapper {
  position: relative;
  width: 360px;
  max-width: 100vw;
  height: 520px;
  overflow: hidden;
}

.imgContainer {
  width: 100%;
}

#blob1, #blob2, #img1, #img3, #img4, #img6 {
  position: absolute;
}

#img1, #img3, #img4, #img6 {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

#img1 {
  width: 70%;
  top: 12%;
  left: 18%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

#img3 {
  width: 70%;
  top: 39%;
  left: 18%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

#img4 {
  width: 45%;
  border-radius: 20px;
  left: 53%;
  top: 40%;
}

#img6 {
  width: 45%;
  border-radius: 20px;
  left: 3%;
  top: 30%;
}

#blob1 {
  width: 50%;
  top: 5%;
  transform: rotate(90deg);
}

#blob2 {
  top: 27%;
  left: 12%;
  width: 80%;
  transform: rotate(45deg);
}




    
#img6 {
  width: 45%;
  border-radius: 20px;
  left: 3%;
  top: 36%;
  transform: rotate(-20deg);
}

#img4 {
  width: 38%;
  border-radius: 15px;
  left: 60%;
  top: 28%;
  transform: rotate(15deg);
}


#img3 {
  width: 70%;
  top: 39%;
  left: 18%;
  transform: rotate(6deg);
  border-radius: 15px;
}

#img1 {
  width: 70%;
  top: 12%;
  left: 18%;
  transform: rotate(-12deg);
  border-radius: 15px;
}
#recaptchaContainer {
  padding-bottom: 20px;
}
/*#recaptchaWrapper {
  position: relative;
}

#recaptchaContainer {
  position: absolute;
}*/

